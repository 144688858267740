import React, { useState } from "react";
import { Check } from "react-feather";
import Button from "../buttons/Button";
import View from "../view/View";
import styles from "./styles/RadioButton.module.css";

export default function RadioButton(props) {
  
  const [accepted, setAccepted] = useState(props.active || false);

  const handleChange = () => {
    const newValue = !accepted;
    setAccepted(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };
  

  return (
    <Button onClick={props.onClick || handleChange} className={`${styles.radioButton} ${props.className}`}>
      <View
        className={`${styles.radioButtonContainer} ${
          props.className && props.className
        }`}
      >
        <View
          className={`${styles.checkbox} ${accepted && styles.checkedCheckbox}`}
        >
          {
            accepted && (
              <Check size={15} className={styles.checkboxIcon} />
            )
          }
        </View>
        {props.component}
      </View>
    </Button>
  );
}
