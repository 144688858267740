import React, { useContext } from "react";
import { OverlayContext } from "../../context/OverlayProvider";
import styles from "./styles/Overlay.module.css";

export default function Overlay(props) {
  
  const { closeOverlay } = useContext(OverlayContext);

  const handleClick = (event) => {
    // Prevent event propagation to parent elements
    event.stopPropagation();
    closeOverlay();
  };

  return (
    <div
      props={props}
      className={`${styles.defaults} ${
        props.isVisible ? styles.fadeIn : styles.fadeOut
      }`}
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
}
