import React, { createContext, useState } from "react";

export const SectionContext = createContext();

export function SectionProvider({ children }) {

  const [sectionData, setSectionData] = useState(false);

  return (
    <SectionContext.Provider value={{ sectionData, setSectionData }}>
      {children}
    </SectionContext.Provider>
  );
}
