import React, { createContext, useEffect, useState } from "react";

export const RadioCellContext = createContext();

export function RadioCellProvider({ children }) {

  const [cache, setCache] = useState({});

  useEffect(() => {
    console.log(cache);
  }, [cache]);

  return (
    <RadioCellContext.Provider value={{ cache, setCache }}>
      {children}
    </RadioCellContext.Provider>
  );
}
