import React, { useContext, useEffect, useState } from "react";
import { AlertCircle, Check, CheckCircle, Info } from "react-feather";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import styles from "./styles/Alert.module.css";
import { AlertContext } from "../../context/AlertProvider";

export default function Alert(props) {
  const { hideAlert } = useContext(AlertContext);

  const [state, setState] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setState(1);
    }, 225);
  }, []);

  useEffect(() => {
    if (state === 1) {
      setTimeout(() => {
        setState(2);
      }, 5000);
    } else if (state === 2) {
      setTimeout(() => {
        hideAlert();
      }, 1000);
    }
  }, [state]);

  function Icon() {
    switch (props.type) {

      case "success":
        return <CheckCircle color="var(--green)" size="16"></CheckCircle>;

      case "error":
        return <AlertCircle color="var(--red)" size="16"></AlertCircle>;

      case "info":
        return <Info color="var(--neutral-100)" size="16"></Info>;
    }
  }

  return (
    <View
      className={`${styles.alertContainer} ${
        state === 1 ? styles.slideDownFadeIn : ""
      }`}
    >
      <View centerVertically gap="small" className={styles.alertComponent}>
        <Icon />
        <Paragraph className={styles.alertParagraph}>{props.message}</Paragraph>
      </View>
    </View>
  );
}
